
import { Component, Prop, Vue } from "nuxt-property-decorator";

@Component
export default class DynamicTable extends Vue {
  @Prop() headers: [string];
  @Prop() data: { [key: string]: string | number | null }[];
  @Prop({ default: false }) isHistoricalData: boolean;

  dividableByTwo(number: number): boolean {
    return number % 2 ? true : false;
  }
}
