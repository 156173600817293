
// Libraries
import { Component, Prop, Vue } from "nuxt-property-decorator";
import DynamicTable from "~/components/organisms/table/DynamicTable.vue";
import { HistoricalOilPriceData } from "~/types/HistoricalOilPriceData";

@Component({
  components: {
    DynamicTable,
  },
  methods: {},
})
export default class PriceComparisonHistoricalData extends Vue {
  @Prop() historicalHighestData: {
    type: HistoricalOilPriceData[];
    required: true;
  };
  @Prop() historicalLowestData: {
    type: HistoricalOilPriceData[];
    required: true;
  };
  @Prop() title: {
    type: string;
    required: true;
  };

  @Prop() hasCustomStyle: {
    type: boolean;
    default: false;
  };

  @Prop() isHistoricalData: {
    type: boolean;
    default: false;
  };
}
